import React from "react";
import {connect} from "react-redux";
import {
    createActions,
    WIDGET_BUTTON,
    WIDGET_CHECKBOX,
    WIDGET_DISPLAY,
    WIDGET_RADIO,
    WIDGET_SWITCH,
} from "../store/widgets";
import {Button, Switch} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

class Widget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    renderControlEntry(value, key) {
        switch (value.get("type")) {
            case WIDGET_BUTTON:
                return (<Button disabled={value.get("disabled")} variant="outlined"
                                style={{width: "100%"}}
                                onClick={() => this.props.onClicked(key)}
                                color={value.get("value") ? "secondary" : "default"}>{value.get("name")}</Button>);
            case WIDGET_CHECKBOX:
                return (<FormControlLabel disabled={value.get("disabled")} control={
                    <Checkbox checked={value.get("value")}
                              onChange={(event) =>
                                  this.props.setValue(key, event.target.checked)}/>
                } label={value.get("name")}/>);
            case WIDGET_SWITCH:
                return (<FormControlLabel disabled={value.get("disabled")} control={
                    <Switch checked={value.get("value")}
                            onChange={(event) =>
                                this.props.setValue(key, event.target.checked)}/>
                } label={value.get("name")}/>);
            case WIDGET_RADIO:
                return (<RadioGroup value={value.get("value")}
                                    style={{marginBottom: "1em"}}
                                    onChange={(event) =>
                                        this.props.setValue(key, event.target.value)}>
                    <FormLabel component="legend" style={{textAlign: "center"}}>{value.get("name")}</FormLabel>
                    {value.get("options").map((option, key) => (
                        <FormControlLabel key={key} value={option.get("value")} control={<Radio/>}
                                          label={option.get("name")} disabled={value.get("disabled")} />
                    ))}
                </RadioGroup>);
            case WIDGET_DISPLAY:
                return (<div>
                    <FormLabel component="legend" style={{textAlign: "center"}}>{value.get("name")}</FormLabel>
                    <Grid container>
                        <Grid item xs={value.get("unit") === null ? 12 : 10}>
                            <Paper style={{textAlign: 'right', padding: '0.3em'}}>
                                <h3 style={{'margin': '0'}}>{value.get("value")}</h3>
                            </Paper>
                        </Grid>
                        {value.get("unit") !== null &&
                        <>
                            <Grid item xs={1}/>
                            <Grid item xs={1} style={{display: "flex", alignItems: "center"}}>
                                <h3 style={{'margin': '0'}}>{value.get("unit")}</h3>
                            </Grid>
                        </>
                        }
                    </Grid>
                </div>);
            default:
                return (<div>Unknown Control "{value.get("type")}"</div>);
        }
    }

    render() {
        return <div style={{padding: '0.5em'}}>
            <h2 style={{marginTop: "0"}}>{this.props.name}</h2>
            <FormControl component="div" style={{textAlign: "left", width: "100%"}}>
                {this.props.controls.map((value, key) => (<div key={key}>{
                    this.renderControlEntry(value, key)
                }</div>)).valueSeq()}
            </FormControl>
        </div>;
    }
}

const mapStateToProps = (state, ownProps) => ({
    key: ownProps.widgetKey,
    name: state.widgets.getIn([ownProps.widgetKey, "name"]),
    controls: state.widgets.getIn([ownProps.widgetKey, "controls"]),
});

const mapDispatchToProps = (dispatch, ownProps) => createActions(dispatch, ownProps.widgetKey);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Widget);