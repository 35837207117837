import {createAction, createSlice} from "@reduxjs/toolkit";
import {store} from "./store";
import Immutable, {List} from "immutable";
import {actionChannel, call, select, take} from "@redux-saga/core/effects";
import ReduxQuerySync from "redux-query-sync";
import {initialize, stopStateMachineWebWorker} from "../statemachineGUI";
import {resetAll} from "./global";
import {loadWidgetConfig, setBaseLayout, setValue} from "./widgets";

export const listStatemachines = createAction(`statemachine/list`);
export const loadStatemachine = createAction(`statemachine/load`);
export const loadConfig = createAction(`statemachine/loadConfig`);
export const saveConfig = createAction(`statemachine/saveConfig`);
export const listConfigs = createAction(`statemachine/listConfigs`);

const statemachineSlice = createSlice({
    name: "statemachine",
    initialState: Immutable.fromJS({
        current: "default",
        currentConfig: null,
        statemachines: ["default"],
        configs: {
            "default": []
        },
        busy: false
    }),
    reducers: {
        setBusy(state, action) {
            return state.set("busy", action.payload);
        },
        setStatemachine(state, action) {
            return state.set("current", action.payload).set("currentConfig", null);
        },
        setConfig(state, action) {
            return state.set("currentConfig", action.payload);
        },
        updateConfigs(state, action) {
            const {statemachine, value} = action.payload;
            return state.setIn(["configs", statemachine], value);
        },
        updateStatemachines(state, action) {
            return state.set("statemachines", action.payload);
        },
    },
});

export const {setStatemachine, setConfig} = statemachineSlice.actions;
export const statemachineReducer = statemachineSlice.reducer;

function createFetch(path, options) {
    return fetch(`${process.env.REACT_APP_STATEMACHINE_API}${path}`,
        {
            ...options,
        })
}

async function apiListStatemachines() {
    const data = await createFetch("statemachine");
    return await data.json()
}

async function apiListConfigs(statemachine) {
    const data = await createFetch(`config/${statemachine}`);
    return await data.json()
}

async function apiLoadConfig(statemachine, config) {
    const data = await createFetch(`config/${statemachine}/${config}`);
    return await data.json()
}

async function apiSaveConfig(statemachine, config, data) {
    return await createFetch(`config/${statemachine}/${config}`, {
        method: "PUT",
        body: JSON.stringify(data)
    });
}

function* listConfigsHandler() {
    let configs = [];
    let statemachine = yield select(state => state.statemachine.get("current"));
    const data = yield call(apiListConfigs, statemachine);
    if (data.paths != null) {
        for (const path of data.paths) {
            configs.push(path.name.replace(`${statemachine}/`,""));
        }
        store.dispatch(statemachineSlice.actions.updateConfigs({
            statemachine,
            value: List(configs)
        }));
    }
}

export function* statemachineSaga() {
    let queryChannel = yield actionChannel([listStatemachines, loadStatemachine, listConfigs, loadConfig, saveConfig]);
    while (true) {
        let {type, payload} = yield take(queryChannel);
        store.dispatch(statemachineSlice.actions.setBusy(true));
        try {
            if (type === listStatemachines.type) {
                let statemachines = [];
                const data = yield call(apiListStatemachines);
                if (data.paths != null) {
                    for (const path of data.paths) {
                        statemachines.push(path.name);
                    }
                    store.dispatch(statemachineSlice.actions.updateStatemachines(List(statemachines)));
                }
            } else if (type === loadStatemachine.type) {
                let statemachine = yield select(state => state.statemachine.get("current"));
                stopStateMachineWebWorker();
                store.dispatch(resetAll());
                initialize(`${process.env.REACT_APP_STATEMACHINE_API}statemachine/${statemachine}/`);
            } else if (type === listConfigs.type) {
                yield listConfigsHandler();
            } else if (type === loadConfig.type) {
                let statemachine = yield select(state => state.statemachine);
                if (statemachine.get("currentConfig") != null) {
                    const data = yield call(apiLoadConfig, statemachine.get("current"), statemachine.get("currentConfig"));
                    store.dispatch(setBaseLayout(data.layout));
                    store.dispatch(loadWidgetConfig(data.values));
                } else {
                    store.dispatch(setBaseLayout({}));
                    store.dispatch(loadWidgetConfig(null));
                }
            } else if (type === saveConfig.type) {
                let {name, value: layout} = payload;
                if (name != null) {
                    let statemachine = yield select(state => state.statemachine.get("current"));
                    let widgets = yield select(state => state.widgets);
                    let values = [];
                    widgets.map((value, key_a) => {
                        return value.get("controls").map((value, key_b) => {
                            values.push([[key_a, key_b],value.get("value")])
                        })
                    });
                    yield call(apiSaveConfig, statemachine, name, {layout, values});
                    yield listConfigsHandler();
                    store.dispatch(setBaseLayout(layout));
                    store.dispatch(setConfig(name));
                }
            }
        } catch (e) {
            console.log(e);
        }

        store.dispatch(statemachineSlice.actions.setBusy(false));
    }
}

export const urlParamEnhancer = ReduxQuerySync.enhancer({
    params: {
        statemachine: {
            selector: state => state.statemachine.get("current"),
            action: value => statemachineSlice.actions.setStatemachine(value),
            defaultValue: "default"
        },
        config: {
            selector: state => state.statemachine.get("currentConfig"),
            action: value => statemachineSlice.actions.setConfig(value),
            defaultValue: null
        },
    }, initialTruth: 'location'
});