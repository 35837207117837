import React from "react";
import MUIDataTable from "mui-datatables";
import {withStyles} from "@material-ui/core";
import {connect} from "react-redux";

const columns = [
    {
        name: "time",
        label: "Time",
        options: {
            filter: false,
            sort: true,
        },
    },
    {
        name: "type",
        label: "Type",
        options: {
            filter: true,
            sort: false,
        },
    },
    {
        name: "event",
        label: "Event",
        options: {
            filter: false,
            sort: false,
        },
    },
];

const options = {
    filterType: "multiselect",
    responsive: 'standard',
    selectableRows: "none",
    pagination: false,
    print: false,
    //download: false
};


const styles = (theme) => ({
    table: {
        maxHeight: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column"
    }
});

class EventView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return <MUIDataTable
            title={"Events"}
            data={this.props.events.toJS()}
            columns={columns}
            options={options}
            className={this.props.classes.table}
        />;
    }
}

const mapStateToProps = (state, ownProps) => ({
    events: state.events,
});

const mapDispatchToProps = {};

export default withStyles(styles, {withTheme: true})(connect(
    mapStateToProps,
    mapDispatchToProps,
)(EventView));
