import React from "react";
import {withStyles} from "@material-ui/core";
import {connect} from "react-redux";
import {withResizeDetector} from "react-resize-detector";

const styles = (theme) => ({});

class StatusView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {scale: 1};
        this.okText = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {width, height} = this.props;
        if (height !== prevProps.height &&
            width && height && this.okText.current) {
            const textBBox = this.okText.current.getBBox();
            const scale = height / textBBox.height;
            this.setState({scale: this.state.scale * scale});
        }
    }

    renderOK() {
        return <>
            <text x="2%" y="75%" lengthAdjust="spacingAndGlyphs" textLength={this.props.width * 0.9 || 1}
                  ref={this.okText} fontSize={`${this.state.scale}em`}>OK
            </text>
        </>;
    }

    renderError() {
        return <>
            <line x1="5%" y1="5%" x2="95%" y2="95%" style={{stroke: "red", strokeWidth: 5}}/>
            <line x1="5%" y1="95%" x2="95%" y2="5%" style={{stroke: "red", strokeWidth: 5}}/>
        </>;
    }

    render() {
        return <div style={{width: "100%", height: "100%"}}>
            <svg style={{width: "100%", height: "100%"}}>
                {this.props.status ? this.renderOK() : this.renderError()}
            </svg>
        </div>;
    }
}

const mapStateToProps = (state, ownProps) => ({
    status: state.status,
});

const mapDispatchToProps = {};

export default withResizeDetector(withStyles(styles, {withTheme: true})(connect(
    mapStateToProps,
    mapDispatchToProps,
)(StatusView)));
