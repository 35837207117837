import {actionChannel, delay, flush, fork, race, select, take} from "@redux-saga/core/effects";
import {voiceGetFile, voiceGetText} from "../voice";
import {createAction, createSlice} from "@reduxjs/toolkit";
import {store} from "./store";
import {addEvent, EVENT_SIGNAL, EVENT_VOICE} from "./misc";
import {broadcast} from "./fsm";
import Immutable from "immutable";
import {resetAll} from "./global";

const PROMPTFINISHED_EVENT = "EVT_Prompt_Finished";

const audioSlice = createSlice({
    name: "audio",
    initialState: Immutable.fromJS({
        language: "en",
        debug: false
    }),
    reducers: {
        setLanguage(state, action) {
            return state.set("language", action.payload);
        },
        setDebug(state, action) {
            return state.set("debug", action.payload);
        },
    },
    extraReducers: {
        [resetAll]: (state) => state.set("language", "en"),
    }
});

export const {setLang: setAudioLanguage, setDebug: setAudioDebug} = audioSlice.actions;
export const audioReducer = audioSlice.reducer;


function createAudioSaga(name, logEvent) {
    const playAction = createAction(`${name}/play`);
    const ttsAction = createAction(`${name}/tts`);
    const stopAction = createAction(`${name}/stop`);
    const finishAction = createAction(`${name}/finish`);;

    function* playAudioFile(payload, audioChannel, debugSkip) {
        let language = yield select(state => state.audio.get("language"));
        let audio = new Audio(voiceGetFile(language, payload));
        audio.addEventListener("playing", event => {
            store.dispatch(addEvent(logEvent, voiceGetText(language, payload)));
        });
        audio.addEventListener("ended", event => {
            store.dispatch(finishAction());
        });
        audio.play();
        let {stop} = yield race({
            play: take(finishAction),
            stop: take([stopAction, resetAll]),
        });
        if (stop) {
            audio.pause();
            yield flush(audioChannel);
        }
    }

    function* playTTS(payload, audioChannel) {
        let tts = new SpeechSynthesisUtterance(payload);
        tts.addEventListener("start", event => {
            store.dispatch(addEvent(logEvent, `TTS: ${payload}`));
        })
        tts.addEventListener("end", event => {
            store.dispatch(finishAction());
        })
        window.speechSynthesis.speak(tts);
        let {stop} = yield race({
            play: take(finishAction),
            stop: take([stopAction, resetAll]),
        });
        if (stop) {
            window.speechSynthesis.cancel();
            yield flush(audioChannel);
        }
    }

    const saga = function* () {
        let audioChannel = yield actionChannel([playAction, ttsAction]);
        while (true) {
            let {type, payload} = yield take(audioChannel);
            let debug_skip = yield select(state => state.audio.get("debug"));
            if (debug_skip) {
                yield delay(100);
                if (type === playAction.type) {
                    let language = yield select(state => state.audio.get("language"));
                    store.dispatch(addEvent(logEvent, voiceGetText(language, payload)));
                } else if (type === ttsAction.type) {
                    store.dispatch(addEvent(logEvent, `TTS: ${payload}`));
                }
            } else {
                if (type === playAction.type) {
                    yield playAudioFile(payload, audioChannel);
                } else if (type === ttsAction.type) {
                    yield playTTS(payload, audioChannel);
                }
            }

            if(name === "voice")
            {
                yield broadcast(PROMPTFINISHED_EVENT);
            }
        }
    };

    return {saga, playAction, ttsAction, stopAction};
}

export const {saga: sagaVoice, playAction: playVoice, ttsAction: ttsVoice, stopAction: stopVoice} = createAudioSaga("voice", EVENT_VOICE);
export const {saga: sagaSignal, playAction: playSignal, stopAction: stopSignal} = createAudioSaga("signal", EVENT_SIGNAL);

export function* audioSaga() {
    yield fork(sagaVoice);
    yield fork(sagaSignal);
}