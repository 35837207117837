import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "../node_modules/react-grid-layout/css/styles.css";
import "../node_modules/react-resizable/css/styles.css";
import App from "./App";
import {Provider} from "react-redux";
import {store} from "./store/store";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";

export const muiTheme = createMuiTheme({
    overrides: {}
})

ReactDOM.render(
    <MuiThemeProvider theme={muiTheme}>
        <Provider store={store}>
            <React.StrictMode>
                <App/>
            </React.StrictMode>
        </Provider>
    </MuiThemeProvider>,
    document.getElementById("root"),
);
