/** Implement state tracking of some GUI sidgets, like AED buttons, Main FSM state view,
 * impedance slider, events list
 */
import {createSlice} from "@reduxjs/toolkit";
import {Map, Stack} from "immutable";
import {resetAll} from "./global";

const mainFsmStateSlice = createSlice({
    name: "mainFsmState",
    initialState: "",
    reducers: {
        set(state, action) {
            return action.payload;
        }
    },
    extraReducers: {
        [resetAll]: (state) => "",
    }
});

export const {set: setMainFsmState} = mainFsmStateSlice.actions;
export const mainFsmStateReducer = mainFsmStateSlice.reducer;

const impedanceSlice = createSlice({
    name: "impedance",
    initialState: 250,
    reducers: {
        set(state, action) {
            return action.payload;
        }
    },
});

export const {set: setImpedance} = impedanceSlice.actions;
export const impedanceReducer = impedanceSlice.reducer;

export const EVENT_STATE = "STATE";
export const EVENT_VOICE = "VOICE";
export const EVENT_SIGNAL = "SIGNAL";
export const EVENT_EVENT = "EVENT";

const eventsSlice = createSlice({
    name: "events",
    initialState: Stack(),
    reducers: {
        add: {
            reducer(state, action) {
                return state.push(action.payload);
            },
            prepare(type, event) {
                return {payload: {time: performance.now(), type, event}};
            }
        }
    },
    extraReducers: {
        [resetAll]: (state) => Stack(),
    }
});

export const {add: addEvent} = eventsSlice.actions;
export const eventsReducer = eventsSlice.reducer;

const controlSlice = createSlice({
    name: "control",
    initialState: Map({
        "shock": false,
        "language": false,
        "power": false,
        "pediatric": false
    }),
    reducers: {
        click(state) {
            return state;
        },
        setBacklight: {
            reducer(state, action) {
                let {button, value} = action.payload;
                return state.set(button, value);
            },
            prepare(button, value) {
                return {payload: {button, value}};
            }
        }
    },
    extraReducers: {
        [resetAll]: (state) => Map({
            "shock": false,
            "language": false,
            "power": false,
            "pediatric": false
        }),
    }
});

export const {click, setBacklight} = controlSlice.actions;
export const controlReducer = controlSlice.reducer;

const statusSlice = createSlice({
    name: "status",
    initialState: true,
    reducers: {
        set(state, action) {
            return action.payload;
        }
    },
    extraReducers: {
        [resetAll]: (state) => true,
    }
});

export const {set: setStatus} = statusSlice.actions;
export const statusReducer = statusSlice.reducer;