import {store} from "./store/store";
import {playSignal, playVoice, ttsVoice, setAudioLanguage, stopSignal, stopVoice} from "./store/audio";
import {addEvent, EVENT_STATE, setBacklight, setMainFsmState} from "./store/misc";
import {setDisabled, setValue} from "./store/widgets";

const VALUE_SENT_EVENT = "EVT_ValueSent";

let stateMachineWorker = null;

function startStateMachineWebWorker(path) {
    if (stateMachineWorker == null) {
        /*const workerUrl = `${path}html/ManagerWorker.js`;
        const workerBlob = new Blob([
            'importScripts(' + JSON.stringify(workerUrl) + ')',
        ], {type: 'application/javascript'});
        const blobUrl = window.URL.createObjectURL(workerBlob);*/
        stateMachineWorker = new Worker(`${path}html/ManagerWorker.js`);
        stateMachineWorker.onmessage = function (e) {
            if (e.data.cmd === "activeStateResponse") {
                onActiveStateResonse(e.data.instance, e.data.value);
            } else if (e.data.cmd === "runtimeValueResponse") {
                onRuntimeValueResponse(e.data.name, e.data.value);
            } else if (e.data.cmd === "playSignal") {
                store.dispatch(playSignal(e.data.value));
            } else if (e.data.cmd === "playVoice") {
                store.dispatch(playVoice(e.data.value));
            } else if (e.data.cmd === "ttsVoice") {
                store.dispatch(ttsVoice(e.data.value));
            } else if (e.data.cmd === "stopSignal") {
                store.dispatch(stopSignal());
            } else if (e.data.cmd === "stopVoice") {
                store.dispatch(stopVoice());
            } else if (e.data.cmd === "setBacklight") {
                store.dispatch(setBacklight(e.data.button, e.data.value));
            } else if (e.data.cmd === "setDisabled") {
                store.dispatch(setDisabled({widget: e.data.widget, control: e.data.control, value: e.data.value}))
            } else if (e.data.cmd === "setValue") {
                store.dispatch(setValue({widget: e.data.widget, control: e.data.control, value: e.data.value}))
            } else if (e.data.cmd === "requestStoreValue") {
                let {category, path, fsm, member} = e.data;
                let storeValue = store.getState()[category].getIn(path);
                fsmSetMember(fsm, member, storeValue);
            } else if (e.data.cmd === "requestWidgetValue") {
                /*
                if(typeof self !== 'undefined' && self.postMessage)
                {
	                self.postMessage({'cmd': 'requestWidgetValue', 'fsm': 'SafetyMonitor', 'member': 'wdt_state',
	                    'widget': 'errors','control':'wdt'});
                }
                //wait for ValueSent event
                 */
                let {fsm, member, widget, control} = e.data;
                let widgetValue = store.getState().widgets.getIn([widget, "controls", control, "value"]);
                fsmSetMember(fsm, [[member, widgetValue]]);
            } else if (e.data.cmd === "requestMultipleWidgetValue") {
                let {fsm, requests} = e.data;
                let widgetValues = [];
                for (const [member, widget, control] of requests) {
                    let widgetValue = store.getState().widgets.getIn([widget, "controls", control, "value"]);
                    widgetValues.push([member, widgetValue]);
                }
                fsmSetMember(fsm, widgetValues);
            } else if (e.data.cmd === "setLanguage") {
                store.dispatch(setAudioLanguage(e.data.value));
            } else if (e.data.cmd === "sendStoreAction") {
                store.dispatch(e.data);
            }
        };
        //start the stateMachineWorker
        stateMachineWorker.postMessage({"cmd": "worker_thread"});
        // start the statemachine
        runCommand("run");
    }
}

// eslint-disable-next-line
export function stopStateMachineWebWorker() {
    if (stateMachineWorker != null) {
        stateMachineWorker.terminate();
        stateMachineWorker = null;
    }
}

function runCommand(command_str) {
    if (stateMachineWorker != null) {
        stateMachineWorker.postMessage({"cmd": "run", "value": command_str});
        stateMachineWorker.postMessage({"cmd": "run", "value": "stepall"});
    }
};

/**
 * Send a request to FSM about it's state. Answer will be processed in startStateMachineWebWorker
 * @param instance
 * @constructor
 */
function RequestActiveState(instance) {
    if (stateMachineWorker != null) {
        stateMachineWorker.postMessage({"cmd": "getActiveState", "instanceName": instance});
    }
}

function RequestRuntimeValue(variableName) {
    if (stateMachineWorker != null) {
        stateMachineWorker.postMessage({"cmd": "getRunState", "value": variableName});
    }
}

let lastState = {};

function onActiveStateResonse(instance, activeStateVal) {
    if (activeStateVal !== lastState[instance]) {
        switch (instance) {
            case "MainCtrl":
                onActiveStateResonse_MainCtrl(activeStateVal);
                break;
            default:
                break;
        }
        lastState[instance] = activeStateVal;
        store.dispatch(addEvent(EVENT_STATE, activeStateVal));
    }
}

function onActiveStateResonse_MainCtrl(activeStateVal) {
    store.dispatch(setMainFsmState(activeStateVal));
}


function onRuntimeValueResponse(variableName, variableValue) {

}

export function sendEvent(dstFsm, eventStr) {
    runCommand(`send ${eventStr} to ${dstFsm}`);
    RequestActiveState("MainCtrl");
}

export function broadcast(eventStr) {
    runCommand("broadcast " + eventStr);
    RequestActiveState("MainCtrl");
}

export function initialize(path) {
    startStateMachineWebWorker(path);
    RequestActiveState("MainCtrl");
}

export function fsmSetMember(fsm, values) {
    for (const [member, value] of values) {
        if(typeof(value) == "string")
            stateMachineWorker.postMessage({"cmd": "setRunState", "value": `${fsm}.${member}='${value}'`});
        else
            stateMachineWorker.postMessage({"cmd": "setRunState", "value": `${fsm}.${member}=` + value});
        RequestRuntimeValue(`${fsm}.${member}`);
    }
    runCommand(`send ${VALUE_SENT_EVENT} to ${fsm}`);
    RequestActiveState("MainCtrl");
}