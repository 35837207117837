export const VOICE_DATA = {
    "START_CPR": 1,
    "GIVE_30_CHEST_COMPRESSIONS": 2,
    "GIVE_15_CHEST_COMPRESSIONS": 3,
    "GIVE_CHEST_COMPRESSIONS": 4,
    "CC_BEEP": 5,
    "GIVE_2_RESCUE_BREATHS": 6,
    "BREATHE_TONE": 7,
    "DEVICE_SWITCHED_ON": 8,
    "OPERATING_MODE_CHANGED": 9,
    "CPR_COMPRESSION_DEPTH_TOO_HIGH": 10,
    "CPR_COMPRESSION_DEPTH_TOO_LOW": 11,
    "ADULT_MODE": 12,
    "PEDIATRIC_MODE": 13,
    "CALL_EMERGENCY_SERVICES": 14,
    "PLUG_IN_ELECTRODES": 15,
    "SWITCH_TO_CPR": 16,
    "ATTACH_ELECTRODES": 17,
    "ATTACH_ELECTRODES_DETAILED": 18,
    "STAND_CLEAR_OF_PATIENT": 19,
    "SHOCK_WILL_BE_DELIVERED_IN": 20,
    "DELIVER_SHOCK_NOW": 21,
    "DEFIBRILLATION_RELEASED": 22,
    "NO_DEFIBRILLATION_RELEASED": 23,
    "SHOCK_READY": 24,
    "CC_BEEP_HIGHER": 25,
    "PRESS_FLASHING_SHOCK_BUTTON": 26,
    "CHARGE_FAILED": 27,
    "SHOCK_FAILED": 28,
    "CPR_COMPRESSION_FRE_TOO_SLOW": 29,
    "CPR_COMPRESSION_FRE_TOO_FAST": 30,
    "INCOMPLETE_RECOIL": 31,
    "LOW_BATTERY": 32,
    "BATTERY_DEPLETED": 33,
    "SHOCK_BAD": 34,
    "ANALYZE_ECG": 35,
    "REMOVE_CLOTHES": 36,
    "ECG_CHANGED": 37,
    "SHOCK_NOT_PRESSED": 38,
    "IMPEDANCE_HIGH": 39,
    "IMPEDANCE_LOW": 40,
    "LANGUAGE": 41,
    "PEDIATRIC_ADVICE": 42,
    "4X_CPR_BEEP": 43,
    "2X_BREATH": 44,
    "STOP_CPR": 45,
    "HOW_TO_RESCUE_BREATH": 46,
};

export const VOICE_TEXT_DE = {
    1: "Reanimation.",
    2: "Geben sie 30 Brustkompressionen.",
    3: "Geben sie 15 Brustkompressionen.",
    4: "Brustkompressionen geben.",
    5: "*beep*",
    6: "2 Rettungsatem geben.",
    7: "*start jingle*",
    8: "Gerät einschalten.",
    9: "Betriebsart geändert.",
    10: "CPR Kompressionstiefe zu groß.",
    11: "CPR Kompressionstiefe zu gering.",
    12: "Erwachsenenmodus.",
    13: "Kindermodus.",
    14: "Notdienste anrufen.",
    15: "Elektrode einstecken.",
    16: "Das Gerät wechselt in den CPR  Modus. Schließen sie die Elektroden an um zur Defibrillation zurückzukehren.",
    17: "Befestigen sie die Elektrode.",
    18: "Legen sie die Elektroden auf die nackte Bruste des Patienten nach einander.",
    19: "Vom Patienten fernhalten.",
    20: "Der Schock wird in 3... 2... 1... geliefert.",
    21: "Liefere jetzt einen Shock ab.",
    22: "Defibrillation freigegeben.",
    23: "Keine Defibrillation freigegeben.",
    24: "*long beep*",
    25: "*short beep*",
    26: "Drücken sie die blinkende Shock Taste.",
    27: "Gerätefehler. Aufladung fehlgeschlagen.",
    28: "Gerätefehler. Schock fehlgeschlagen.",
    29: "CPR Komprimierungsfrequenz zu langsam.",
    30: "CPR Komprimierungsfrequenz zu schnell.",
    31: "Unvollständiger Rückstoß.",
    32: "Niedriger Batteriestatus. Bitte ersetzen sie die Batterie sobald wie möglich.",
    33: "Batterie lehr. Bitte ersetzen sie die Batterie sofort.",
    34: "Kein Schock empfohlen.",
    35: "Herzrythmus analysieren.",
    36: "Entfernen sie die Kleidung von der Brust des Patienten.",
    37: "Rythmusänderung.",
    38: "Schock Taste nicht gedrückt.",
    39: "Hohe Impedanz.",
    40: "Niedrige Impedanz.",
    41: "Deutsch.",
    42: "drucken Sie pediatrie Taste für Kinder",
    43: "CPR beep",
    44: "CPR Breath",
    45: "Stop CPR",
};

export const VOICE_TEXT_EN = {
    1: "Reanimation.",
    2: "Geben sie 30 Brustkompressionen.",
    3: "Geben sie 15 Brustkompressionen.",
    4: "Brustkompressionen geben.",
    5: "*beep*",
    6: "2 Rettungsatem geben.",
    7: "*start jingle*",
    8: "Gerät einschalten.",
    9: "Betriebsart geändert.",
    10: "CPR Kompressionstiefe zu groß.",
    11: "CPR Kompressionstiefe zu gering.",
    12: "Erwachsenenmodus.",
    13: "Kindermodus.",
    14: "Notdienste anrufen.",
    15: "Elektrode einstecken.",
    16: "Das Gerät wechselt in den CPR  Modus. Schließen sie die Elektroden an um zur Defibrillation zurückzukehren.",
    17: "Befestigen sie die Elektrode.",
    18: "Legen sie die Elektroden auf die nackte Bruste des Patienten nach einander.",
    19: "Vom Patienten fernhalten.",
    20: "Der Schock wird in 3... 2... 1... geliefert.",
    21: "Liefere jetzt einen Shock ab.",
    22: "Defibrillation freigegeben.",
    23: "Keine Defibrillation freigegeben.",
    24: "*long beep*",
    25: "*short beep*",
    26: "Drücken sie die blinkende Shock Taste.",
    27: "Gerätefehler. Aufladung fehlgeschlagen.",
    28: "Gerätefehler. Schock fehlgeschlagen.",
    29: "CPR Komprimierungsfrequenz zu langsam.",
    30: "CPR Komprimierungsfrequenz zu schnell.",
    31: "Unvollständiger Rückstoß.",
    32: "Niedriger Batteriestatus. Bitte ersetzen sie die Batterie sobald wie möglich.",
    33: "Batterie lehr. Bitte ersetzen sie die Batterie sofort.",
    34: "Kein Schock empfohlen.",
    35: "Herzrythmus analysieren.",
    36: "Entfernen sie die Kleidung von der Brust des Patienten.",
    37: "Rythmusänderung.",
    38: "Schock Taste nicht gedrückt.",
    39: "Hohe Impedanz.",
    40: "Niedrige Impedanz.",
    41: "Deutsch.",
    42: "drucken Sie pediatrie Taste für Kinder",
    43: "CPR beep",
    44: "CPR Breath",
    45: "Stop CPR",
};

export const VOICE_TEXT = {
    "de": VOICE_TEXT_DE,
    "en": VOICE_TEXT_EN,
};

export function voiceGetFile(language, voice) {
    if (voice in VOICE_DATA) {
        let voice_id = VOICE_DATA[voice];
        return `audio/${language}/${voice_id}.wav`;
    }
    return null;
}

export function voiceGetText(language, voice) {
    if (voice in VOICE_DATA && language in VOICE_TEXT) {
        if (VOICE_DATA[voice] in VOICE_TEXT[language]) {
            return VOICE_TEXT[language][VOICE_DATA[voice]];
        }
    }
    return "$AUDIO TEXT MISSING$";
}