import {applyMiddleware, combineReducers, createStore} from "redux";
import {fork} from "@redux-saga/core/effects";
import createSagaMiddleware from "@redux-saga/core";
import {audioReducer, audioSaga, playVoice, ttsVoice} from "./audio";
import {baseLayoutReducer, widgetsReducer, widgetsSaga} from "./widgets";
import {
    controlReducer,
    eventsReducer,
    impedanceReducer,
    mainFsmStateReducer,
    setMainFsmState,
    statusReducer
} from "./misc";
import {fsmSaga} from "./fsm";
import {composeWithDevTools} from "@redux-devtools/extension";
import {statemachineReducer, statemachineSaga, urlParamEnhancer} from "./statemachine";

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = composeWithDevTools({
    actionCreators: {
        playVoice,
        ttsVoice,
        setMainFsmState
    }, trace: true, traceLimit: 25 });

const rootReducer = combineReducers({
    impedance: impedanceReducer, widgets: widgetsReducer,
    mainFsmState: mainFsmStateReducer, events: eventsReducer,
    control: controlReducer, status: statusReducer, audio: audioReducer,
    statemachine: statemachineReducer, baseLayout: baseLayoutReducer
});

export let store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(sagaMiddleware),
    urlParamEnhancer
    ));

function* rootSaga() {
    yield fork(audioSaga);
    yield fork(fsmSaga);
    yield fork(widgetsSaga);
    yield fork(statemachineSaga);
}

sagaMiddleware.run(rootSaga);