import {createSlice} from "@reduxjs/toolkit";
import Immutable, {Stack} from "immutable";
import {fork, select, take} from "@redux-saga/core/effects";
import {broadcast} from "./fsm";
import {resetAll} from "./global";
import {store} from "./store";

export const WIDGET_BUTTON = "WIDGET_BUTTON";
export const WIDGET_CHECKBOX = "WIDGET_CHECKBOX";
export const WIDGET_SWITCH = "WIDGET_SWITCH";
export const WIDGET_RADIO = "WIDGET_RADIO";
export const WIDGET_DISPLAY = "WIDGET_DISPLAY";

export const ECG_NORMAL = "EVT_ECG_Normal";
export const ECG_FIBRILLATION = "EVT_ECG_Fibrilation";
export const ECG_ZERO_LINE = "EVT_ECG_Zeroline";

export const ELECTRODE_ADULT = "ELECTRODE_ADULT";
export const ELECTRODE_PEDIATRIC = "ELECTRODE_PEDIATRIC";
export const ELECTRODE_UNKNOWN = "ELECTRODE_UNKNOWN";

export const BATTERY_FULL = "BATTERY_FULL";
export const BATTERY_ALMOST_FULL = "BATTERY_ALMOST_FULL";
export const BATTERY_EMPTY = "BATTERY_EMPTY";

export const CPR_DEPTH_DEEP = "CPR_DEPTH_DEEP";
export const CPR_DEPTH_NORMAL = "CPR_DEPTH_NORMAL";
export const CPR_DEPTH_SHALLOW = "CPR_DEPTH_SHALLOW";
export const CPR_SPEED_FAST = "CPR_SPEED_FAST";
export const CPR_SPEED_NORMAL = "CPR_SPEED_NORMAL";
export const CPR_SPEED_SLOW = "CPR_SPEED_SLOW";

const initialState = Immutable.fromJS({
    sensors: {
        name: "Sensors",
        layout: {x: 4, y: 1, w: 1, h: 2, minW: 1, minH: 2},
        controls: {
            dmt: _switch("DMT", {event: "EVT_DMT"}),
            wall: _switch("Wall", {value: true, eventOff: "EVT_Unmounted"}),
            cover: _switch("Cover", {value: true, eventOn: "EVT_Cover_Closed", eventOff: "EVT_Cover_Opened"}),

            battery: _switch("Battery", {value: true, eventOff: "EVT_NoEnergy_PowerOff"}),
            aedmode: _switch("FAED", {value: false}),
        }
    },
    electrode: {
        name: "Electrode",
        layout: {x: 5, y: 1, w: 1, h: 2, minW: 1, minH: 2},
        controls: {
            electrode: _switch("Electrode", {value: false, eventOn: "EVT_Electrodes_Connected",eventOff: "EVT_Electrodes_Disconnected"}),
            electrodeType: radio("") //, {event: "EVT_ElectrodeChanged"})
                .option("Adult", ELECTRODE_ADULT)
                //.option("Pediatric", ELECTRODE_PEDIATRIC)
                .option("Unknown", ELECTRODE_UNKNOWN)
                .build()
        }
    },
    battery: {
        name: "Battery",
        layout: {x: 6, y: 1, w: 1, h: 2, minW: 1, minH: 2},
        controls: {
            battery: radio("",{value: BATTERY_FULL, event: "EVT_BatteryStatus"})
                .option("Full", BATTERY_FULL, "EVT_BatteryFull")
                .option("Almost Full", BATTERY_ALMOST_FULL, "EVT_BatteryAlmostFull")
                .option("Empty", BATTERY_EMPTY, "EVT_BatteryEmpty")
                .build()
        }
    },
    errors: {
        name: "Errors",
        layout: {x: 7, y: 1, w: 1, h: 3, minW: 1, minH: 3},
        controls: {
            //shock: checkbox("Shock failure"),
            //wdt: checkbox("WDT"),
            //shortSt: checkbox("Short ST"),
            //relay: checkbox("Relay"),
            //hvBridge: checkbox("HV Bridge"),
            //safetyCircuit: checkbox("Safety Circuit", {eventOn: "EVT_SafetyCircuit"}),
            battery: checkbox("Low Battery", {eventOn: "EVT_NoEnergySoon_PowerOff"}),
            error2: checkbox("Run-time Error", {eventOn: "EVT_DeviceError"}),
            error3: checkbox("Safety Error", {eventOn: "EVT_SafetyError"}),
        }
    },
    cpr: {
        name: "CPR",
        layout: {x: 8, y: 1, w: 1, h: 3, minW: 1, minH: 3},
        controls: {
            cprDepth: radio("Depth", {value: CPR_DEPTH_NORMAL})
                .option("Deep", CPR_DEPTH_DEEP)
                .option("Normal", CPR_DEPTH_NORMAL)
                .option("Shallow", CPR_DEPTH_SHALLOW)
                .build(),
            cprSpeed: radio("Speed", {value: CPR_SPEED_NORMAL})
                .option("Fast", CPR_SPEED_FAST)
                .option("Normal", CPR_SPEED_NORMAL)
                .option("Slow", CPR_SPEED_SLOW)
                .build()
        }
    },
    debug: {
        name: "Debug",
        layout: {x: 3, y: 3, w: 1, h: 1, minW: 1, minH: 1},
        controls: {
            selftest: button("Selftest", {event: "EVT_SelfTest"}),
            callDMT: button("Call DMT", {event: "EVT_CallDMT"})
        }
    },
    shockEnergy: {
        name: "Shock Energy",
        layout: {x: 4, y: 3, w: 1, h: 1, minW: 1, minH: 1},
        controls: {
            shockEnergy: display("", {unit: "J"})
        }
    },
    serviceRequest: {
        name: "Service Request",
        layout: {x: 5, y: 3, w: 2, h: 1, minW: 1, minH: 1},
        controls: {
            configUpdate: checkbox("Config Update")
        }
    },
    ecg: {
        name: "ECG",
        layout: {x: 3, y: 1, w: 1, h: 2, minW: 1, minH: 2},
        controls: {
            status: radio("")
                .option("Zero Line", ECG_ZERO_LINE, ECG_ZERO_LINE)
                .option("Normal", ECG_NORMAL, ECG_NORMAL)
                .option("Fibrillation", ECG_FIBRILLATION, ECG_FIBRILLATION)

                .build(),
            backecg: checkbox("Background ECG")
        }
    },
});

function button(name, {value = false, event = null, disabled = false} = {}) {
    return {type: WIDGET_BUTTON, name, value, disabled, event};
}

function checkbox(name, {value = false, eventOn = null, eventOff = null, disabled = false} = {}) {
    return {type: WIDGET_CHECKBOX, name, value, eventOn, eventOff, disabled};
}

function _switch(name, {value = false, eventOn = null, eventOff = null, disabled = false} = {}) {
    return {type: WIDGET_SWITCH, name, value, eventOn, eventOff, disabled};
}

function radio(name, {value = null, event = null, disabled = false} = {}) {
    return {
        name,
        value,
        event,
        disabled,
        options: [],
        option(name, value, event = null) {
            this.options.push({name, value, event});
            if (this.value === null) {
                this.value = value;
            }
            return this;
        },
        build() {
            return {
                type: WIDGET_RADIO, name: this.name,
                value: this.value, options: this.options,
                event: this.event, disabled: this.disabled
            };
        }
    };
}

function display(name, {value = 0, unit = null} = {}) {
    return {type: WIDGET_DISPLAY, name, value, unit};
}

const widgetsSlice = createSlice({
    name: "widgets",
    initialState,
    reducers: {
        onClicked(state, action) {
            return state;
        },
        setDisabled(state, action) {
            let {widget, control, value} = action.payload;
            return state.setIn([widget, "controls", control, "disabled"], value);
        },
        setValue(state, action) {
            let {widget, control, value} = action.payload;
            return state.setIn([widget, "controls", control, "value"], value);
        },
        loadConfig(state, action) {
            if (action.payload != null) {
                let current = state;
                for (const widget_value of action.payload) {
                    current = current.setIn([widget_value[0][0], "controls", widget_value[0][1], "value"], widget_value[1]);
                }
                return current;
            } else {
                return initialState;
            }
        }
    },
    extraReducers: {
        [resetAll]: (state) => initialState,
    }
});

export function createActions(dispatch, widget) {
    return {
        onClicked: (control) => dispatch(widgetsSlice.actions.onClicked({widget, control})),
        setDisabled: (control, value) => dispatch(widgetsSlice.actions.setDisabled({widget, control, value})),
        setValue: (control, value) => dispatch(widgetsSlice.actions.setValue({widget, control, value})),
    };
}

export const {onClicked, setDisabled, setValue, loadConfig: loadWidgetConfig} = widgetsSlice.actions;
export const widgetsReducer = widgetsSlice.reducer;

const baseLayout = createSlice({
    name: "baseLayout",
    initialState: {},
    reducers: {
        setBaseLayout(state, action) {
            return action.payload
        }
    },
});

export const {setBaseLayout} = baseLayout.actions;
export const baseLayoutReducer = baseLayout.reducer;

function* widgetsClickedSaga() {
    while (true) {
        let click = yield take(widgetsSlice.actions.onClicked.type);
        let {widget, control} = click.payload;
        let event = yield select(state => state.widgets.getIn([widget, "controls", control, "event"]));
        if (event) {
            yield broadcast(event);
        }
    }

}

function* widgetsSetValueSaga() {
    while (true) {
        let click = yield take(widgetsSlice.actions.setValue.type);
        let {widget, control} = click.payload;
        let widgetState = yield select(state => state.widgets.getIn([widget, "controls", control]));
        let value = widgetState.get("value");
        let event = widgetState.get("event");
        let eventOn = widgetState.get("eventOn");
        let eventOff = widgetState.get("eventOff");
        if (value && eventOn) {
            yield broadcast(eventOn);
        } else if (!value && eventOff) {
            yield broadcast(eventOff);
        } else if (event) {
            yield broadcast(event);
        }
        if (widgetState.get("type") === WIDGET_RADIO) {
            for (let opt of widgetState.get("options")) {
                if (opt.get("value") === value && opt.get("event")) {
                    yield broadcast(opt.get("event"));
                }
            }
        }
    }
}

export function* widgetsSaga() {
    yield fork(widgetsClickedSaga);
    yield fork(widgetsSetValueSaga);
}