import React from "react";
import {MenuItem, Paper, Select, Switch, TextField, withStyles} from "@material-ui/core";

import {connect} from "react-redux";
import {initialize} from "./statemachineGUI";
import {Responsive, WidthProvider} from "react-grid-layout";
import Widget from "./components/Widget";
import DeviceControl from "./components/DeviceControl";
import FSMStateView from "./components/StateView";
import ImpedanceControl from "./components/ImpedanceControl";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import EventView from "./components/EventView";
import StatusView from "./components/StatusView";
import {setAudioDebug} from "./store/audio";
import {
    listConfigs,
    listStatemachines,
    loadConfig,
    loadStatemachine, saveConfig,
    setConfig,
    setStatemachine
} from "./store/statemachine";
import {List} from "immutable";

const ResponsiveGridLayout = WidthProvider(Responsive);

const styles = (theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        //padding: theme.spacing(2),
        textAlign: "center",
        width: "100%",
        height: "100%",
        maxHeight: "100%",
        position: "absolute",
        overflow: "hidden"
    },
});

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {lockLayout: true, layouts: props.baseLayout, config: props.statemachine.get("currentConfig") || ""};
    }

    async componentDidMount() {
        this.props.listStatemachines();
        this.props.loadStatemachine();
        this.props.listConfigs();
        this.props.loadConfig();
    }

    onLayoutChange(layout, layouts) {
        this.setState({layouts});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.baseLayout !== this.props.baseLayout) {
            this.setState({layouts: this.props.baseLayout});
        }
    }

    render() {
        return (
            <>
                <AppBar position="static">
                    <Toolbar variant="dense">
                        <Button variant="outlined" color="inherit" style={{marginRight: "1em"}}
                                onClick={() => this.setState({layouts: this.props.baseLayout})}>
                            Reset Layout
                        </Button>
                        <FormControlLabel control={
                            <Switch checked={this.state.lockLayout}
                                    onChange={(event) =>
                                        this.setState({lockLayout: event.target.checked})}/>
                        } label={this.state.lockLayout ? "Unlock Layout" : "Lock Layout"}/>
                        <FormControlLabel style={{flexGrow: "1"}} control={
                            <Switch checked={this.props.debugAudio}
                                    onChange={(event) =>
                                        this.props.setAudioDebug(event.target.checked)}/>
                        } label={this.props.debugAudio ? "Skip Audio" : "Play Audio"}/>
                        <Button variant="outlined" color="inherit" style={{marginRight: "1em"}}
                                onClick={() => {
                                    this.props.saveConfig({name: this.state.config, value: this.state.layouts});
                                }} disabled={this.props.statemachine.get("busy") || this.state.config === ""}>
                            Save Config
                        </Button>
                        <FormControlLabel control={
                            <TextField variant="filled" color="secondary" style={{margin: "0.3em"}} inputProps={{style:{color: "white"}}}
                                       onChange={(event) => this.setState({config: event.target.value})} value={this.state.config}/>
                        } label={""}/>
                        <FormControlLabel control={
                            <Select color="secondary" value={this.props.statemachine.get("currentConfig")} autoWidth={true} style={{color: "white", marginLeft: "1em", marginRight: "1em"}} displayEmpty={true}
                                    onChange={(event) => this.props.setConfig(event.target.value)}>
                                <MenuItem value={null}>None</MenuItem>
                                {this.props.statemachine.getIn(["configs", this.props.statemachine.get("current")], List([])).map((value, key) => (
                                    <MenuItem value={value} key={key}>{value}</MenuItem>
                                )).valueSeq()}
                            </Select>
                        } label={"Config"} labelPlacement={"start"}/>
                        <Button variant="outlined" color="inherit" style={{marginRight: "1em"}}
                                onClick={() => {
                                    this.setState({config: this.props.statemachine.get("currentConfig") || ""});
                                    this.props.loadConfig();
                                }} disabled={this.props.statemachine.get("busy")}>
                            Load Config
                        </Button>
                        <FormControlLabel control={
                            <Select color="secondary" value={this.props.statemachine.get("current")} autoWidth={true} style={{color: "white", marginLeft: "1em", marginRight: "1em"}}
                                    onChange={(event) => {
                                        this.props.setStatemachine(event.target.value)
                                        this.props.listConfigs()
                                    }}>
                                {this.props.statemachine.get("statemachines").map((value, key) => (
                                    <MenuItem value={value} key={key}>{value}</MenuItem>
                                )).valueSeq()}
                            </Select>
                        } label={"Statemachine"} labelPlacement={"start"}/>
                        <Button variant="outlined" color="inherit" style={{marginRight: "1em"}}
                                onClick={() => this.props.loadStatemachine()} disabled={this.props.statemachine.get("busy")}>
                            Reload Statemachine
                        </Button>
                    </Toolbar>
                </AppBar>
                <ResponsiveGridLayout className="layout" breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
                                      cols={{lg: 12, md: 8, sm: 6, xs: 4, xxs: 2}}
                                      compactType={null} preventCollision={true}
                                      layouts={this.state.layouts}
                                      onLayoutChange={(layout, layouts) => this.onLayoutChange(layout, layouts)}
                                      isDraggable={!this.state.lockLayout}
                                      isResizable={!this.state.lockLayout}>
                    <Paper key="a" data-grid={{x: 0, y: 0, w: 3, h: 5}} className={this.props.classes.paper}>
                        <EventView/>
                    </Paper>
                    <Paper key="b" data-grid={{x: 4, y: 0, w: 4, h: 1}} className={this.props.classes.paper}>
                        <DeviceControl/>
                    </Paper>
                    <Paper key="e" data-grid={{x: 8, y: 0, w: 2, h: 1}} className={this.props.classes.paper}>
                        <StatusView/>
                    </Paper>
                    <Paper key="c" data-grid={{x: 10, y: 0, w: 2, h: 4}} className={this.props.classes.paper}>
                        <FSMStateView/>
                    </Paper>
                    <Paper key="d" data-grid={{x: 9, y: 1, w: 1, h: 3}} className={this.props.classes.paper}>
                        <ImpedanceControl/>
                    </Paper>
                    {this.props.widgets.map((value, key) => (
                        <Paper key={key} data-grid={value.get("layout").toJS()} className={this.props.classes.paper}>
                            <Widget widgetKey={key}/>
                        </Paper>
                    )).valueSeq()}

                </ResponsiveGridLayout>
            </>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    events: state.events, widgets: state.widgets, debugAudio: state.audio.get("debug"),
    statemachine: state.statemachine, baseLayout: state.baseLayout
});

const mapDispatchToProps = {
    setAudioDebug, listStatemachines, listConfigs, loadConfig, setStatemachine, setConfig, loadStatemachine, saveConfig
};

export default withStyles(styles, {withTheme: true})(connect(
    mapStateToProps,
    mapDispatchToProps,
)(App));
