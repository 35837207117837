import Slider from "@material-ui/core/Slider";
import React from "react";
import {connect} from "react-redux";
import {setImpedance} from "../store/misc";
import Grid from "@material-ui/core/Grid";

const marks = [
    {
        value: 0,
        label: "0",
    },
    {
        value: 20,
        label: "20",
    },
    {
        value: 200,
        label: "200",
    },
    {
        value: 250,
        label: "250",
    },
];

class ImpedanceControl extends React.Component {
    constructor(props) {
        super(props);
        this.state = {impedance: this.props.impedance};
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.impedance !== prevProps.impedance) {
            this.setState({impedance: this.props.impedance});
        }
    }

    render() {
        return <Grid container direction={"column"} style={{height: "100%", padding: "0.5em"}}
                     alignContent={"center"} wrap={"nowrap"}>
            <Grid item xs={2} style={{maxWidth: "100%"}}>
                <h2 style={{marginTop: "0"}}>Impedance</h2>
            </Grid>
            <Grid item xs={9}>
                <Slider
                    orientation="vertical"
                    value={this.state.impedance}
                    aria-labelledby="vertical-slider"
                    marks={marks}
                    step={10}
                    max={250}
                    onChange={(event, value) => this.setState({impedance: value})}
                    onChangeCommitted={(event, value) => this.props.setImpedance(value)}
                />
            </Grid>
        </Grid>;
    }
}

const mapStateToProps = (state, ownProps) => ({
    impedance: state.impedance
});

const mapDispatchToProps = {
    setImpedance
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ImpedanceControl);