import {broadcast as broadcastGui} from "../statemachineGUI";
import {addEvent, click as controlClick, EVENT_EVENT, setImpedance} from "./misc";
import {delay, fork, put, race, select, take} from "@redux-saga/core/effects";

const CLICK_EVENT_SUFFIX = "Pressed";
const DOUBLE_CLICK_EVENT_SUFFIX = "LongPressed";

const CONTROL_EVENTS = {
    shock: "EVT_Shock_",
    power: "EVT_PowerOn_",
    language: "EVT_Language_",
    pediatric: "EVT_Pediatric_",
}

const IMPEDANCEVALID_EVENT = "EVT_Impedance_Valid"
//const IMPEDANCEINVALID_EVENT = "EVT_Impedance_Invalid"
const LOWIMPEDANCE_EVENT = "EVT_Impedance_Low"
const HIGHIMPEDANCE_EVENT = "EVT_Impedance_High"

const TICK_EVENT = "EVT_Tick100ms";
const TICK_INTERVAL_MS = 100;

const DOUBLE_CLICK_WAITING_TIME_MS = 300;

setInterval(() => {
    broadcastGui(TICK_EVENT);
}, TICK_INTERVAL_MS);

setInterval(() => {
    broadcastGui("EVT_Tick500ms");
}, 510);

setInterval(() => {
    broadcastGui("EVT_Tick1s");
}, 1010);

setInterval(() => {
    broadcastGui("EVT_Tick5s");
}, 5010);

setInterval(() => {
    broadcastGui("EVT_Tick10s");
}, 10010);

/**
 * Called by UI to broadcast events to all FSMs and to push events into GUI list &
 * @param event
 * @returns {Generator<SimpleEffect<"PUT", PutEffectDescriptor<PayloadAction<any, string> | PayloadAction<any, string, never, never> | PayloadAction<any, string, any, never> | PayloadAction<any, string, never, any> | PayloadAction<any, string, any, any>>>, void, *>}
 */
export function* broadcast(event) {
    yield put(addEvent(EVENT_EVENT, event));
    broadcastGui(event);
}

/**
 * Generate "long press" evente by double-click of the AED buttons
 * @returns {Generator<CombinatorEffect<"RACE", SimpleEffect<"CALL", CallEffectDescriptor<true>> | SimpleEffect<"TAKE", TakeEffectDescriptor>>|Generator<SimpleEffect<"PUT", PutEffectDescriptor<PayloadAction<*, string>|PayloadAction<*, string, never, never>|PayloadAction<*, string, *, never>|PayloadAction<*, string, never, *>|PayloadAction<*, string, *, *>>>, void, *>|SimpleEffect<"TAKE", TakeEffectDescriptor>, void, *>}
 */
export function* doubleClickSaga() {
    while (true) {
        let {payload} = yield take(controlClick.type);
        let {doubleClick} = yield race({
            doubleClick: take(controlClick.type),
            timeout: delay(DOUBLE_CLICK_WAITING_TIME_MS),
        });
        if (doubleClick && doubleClick.payload === payload) {
            yield broadcast(`${CONTROL_EVENTS[payload]}${DOUBLE_CLICK_EVENT_SUFFIX}`);
        } else {
            yield broadcast(`${CONTROL_EVENTS[payload]}${CLICK_EVENT_SUFFIX}`);
        }
    }
}

/**
 * Send ImpedanceChange Event, if slider is changed.
 * @returns {Generator<Generator<SimpleEffect<"PUT", PutEffectDescriptor<PayloadAction<*, string>|PayloadAction<*, string, never, never>|PayloadAction<*, string, *, never>|PayloadAction<*, string, never, *>|PayloadAction<*, string, *, *>>>, void, *>|SimpleEffect<"TAKE", TakeEffectDescriptor>|SimpleEffect<"SELECT", SelectEffectDescriptor>, void, *>}
 */
export function* impedanceSaga() {
    while (true) {
        let state = yield select(state => state.impedance);
        let newState = yield take(setImpedance.type);
        if (state !== newState.payload) {
            if(newState.payload<20)
            {
                yield broadcast(LOWIMPEDANCE_EVENT);

            }else if(newState.payload>200) {
                yield broadcast(HIGHIMPEDANCE_EVENT);
            }
            else {
                yield broadcast(IMPEDANCEVALID_EVENT);
            }
        }
    }
}

export function* fsmSaga() {
    yield fork(doubleClickSaga);
    yield fork(impedanceSaga);
}