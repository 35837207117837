import {Button, Grid, Paper} from "@material-ui/core";
import React from "react";
import {connect} from "react-redux";
import {click} from "../store/misc";

class DeviceControl extends React.Component {
    constructor(props) {
        super(props);
        this.state = {mounted: true};

    }

    renderButton(id, text) {
        return <Button disabled={this.props.cover} variant="outlined"
                       onClick={() => this.props.click(id)}
                       style={this.props.backlight.get(id) ? {backgroundColor: "lightgreen"} : {}}>{text}</Button>
    }

    render() {
        return <div>
            <Grid container spacing={3}>
                {/** FIRST ROW **/}
                <Grid item xs={4}>
                    <Paper>
                        Language: {this.props.language}
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper>
                        {this.renderButton("shock", "SHOCK")}
                    </Paper>
                </Grid>
                <Grid item xs={4}>

                </Grid>
                {/** SECOND ROW **/}
                <Grid item xs={4}>
                    <Paper>
                        {this.renderButton("language", "Language")}
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper>
                        {this.renderButton("power", "Power")}
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper>
                        {this.renderButton("pediatric", "Pediatric")}
                    </Paper>
                </Grid>
            </Grid>
        </div>;
    }
}

const mapStateToProps = (state, ownProps) => ({
    backlight: state.control,
    cover: state.widgets.getIn(["sensors", "controls", "cover", "value"]),
    language: state.audio

});

const mapDispatchToProps = {
    click
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DeviceControl);