/**
 * State view implements right table with FSM states. Active state is marked by green bar in the first column.
 * Changes of the MainFSM states shall be mirrored in this file only.
 * Uknown states will be added to the GUI table automatically.
 */
import React from "react";
import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, withStyles} from "@material-ui/core";
import {connect} from "react-redux";

const STATES = {
    "MainControl_MainFSM_POST": "POST",
    "MainControl_MainFSM_Standby": "Standby",
    "MainControl_MainFSM_Therapy_DevicePreparation": "Device Preparation",
    "MainControl_MainFSM_Therapy_Wait_EOP": "Device Preparation",
    "MainControl_MainFSM_Therapy_PatientPreparation":"Patient Preparation",
    "MainControl_MainFSM_Therapy_MonitorCPR":"CPR",
    "MainControl_MainFSM_Therapy_PlayCPRPrompt":"CPR",
    "MainControl_MainFSM_TherapyResuscitation_Resuscitation":"Resuscitation",
    "MainControl_MainFSM_Therapy_Resuscitation_GetECGMode":"Resuscitation",
    "MainControl_MainFSM_Therapy_Resuscitation_GetECGResult":"Resuscitation",
    "MainControl_MainFSM_Therapy_Resuscitation_ShockAdvise":"Shock",
    "MainControl_MainFSM_Therapy_Resuscitation_WaitShock":"Shock",
    "MainControl_MainFSM_TherapyErrorState": "Error State",
    "MainControl_MainFSM_FailSafe": "FailSafe",
    "MainControl_MainControlFSM_LowBatteryShutDown": "Low-Battery Shutdown",
    "MainControl_MainControlFSM_EmergencyShutDown": "Emergency Shutdown",

}

const styles = (theme) => ({
    activeState: {
        backgroundColor: theme.palette.success.main,
    },
});

class StateView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {extraStates: [], override: null};
    }

    /**
     * Adding of unknown states to the list.
     * @param prevProps
     * @param prevState
     * @param snapshot
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!(this.props.state in STATES) && !(this.state.extraStates.includes(this.props.state))) {
            for (const state in STATES) {
                if (this.props.state.startsWith(state)) {
                    if (this.state.override !== state) {
                        this.setState({override: state});
                    }
                    return;
                }
            }
            this.state.extraStates.push(this.props.state);
            this.setState({extraStates: this.state.extraStates, override: null});
        }  else if (this.state.override !== null) {
            this.setState({override: null});
        }
    }

    render() {
        return <TableContainer component={Box}>
            <Table stickyHeader aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Active</TableCell>
                        <TableCell>Name</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.entries(STATES).map(([key, row]) => (
                        <TableRow key={key}>
                            <TableCell component="th" scope="row" className={
                                key === this.props.state || key === this.state.override ? this.props.classes.activeState : ""
                            }/>
                            <TableCell component="th" scope="row">
                                {row}
                            </TableCell>
                        </TableRow>
                    ))}
                    {this.state.extraStates.map((key) => (
                        <TableRow key={key}>
                            <TableCell component="th" scope="row" className={
                                key === this.props.state || key === this.state.override ? this.props.classes.activeState : ""
                            }/>
                            <TableCell component="th" scope="row">
                                {key}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>;
    }
}

/**
 * Binding between the GUI and the store
 * @param state
 * @param ownProps
 * @returns {{state: Reducer<any>}}
 */
const mapStateToProps = (state, ownProps) => ({
    state: state.mainFsmState,
});

const mapDispatchToProps = {};
/** Ein Haufen von Binding u.A. mit CSS, nur Michi weiss wie es genau geht*/
export default withStyles(styles, {withTheme: true})(connect(
    mapStateToProps,
    mapDispatchToProps,
)(StateView));